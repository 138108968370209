import React, { useState, useEffect } from "react";
import "./Login.scss";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const override = css`
  vertical-align: middle;
  margin-left: 6px;
`;

export const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [login, setLogin] = useState(false);

  const handleUserName = (event) => {
    setUserName(event.target.value);
    setErrorText("");
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    setErrorText("");
  };

  const handleSubmit = () => {
    setLoading(true);

    axios
      .post("https://server.risetech.ir/Admin/api/v1/profile/Login", {
        UserName: userName,
        Password: password,
      })

      .then((res) => {
        cookie.save("token", res.data.Message);
        setLoading(false);
        setLogin(true);
      })
      .catch((err) => {
        setErrorText(err.response.data.Message);
        setLoading(false);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  if (login) {
    return <Redirect to="/employee" />;
  }

  return (
    <div className="login_bg">
      <div className="login_holder">
        <figure className="login_logo">
          <img src={require("../../asset/img/logo.png")} />
        </figure>

        <div className="">
          <p className="text_guide">
            برای ورود نام کاربری و رمز عبور خود را وارد کنید.
          </p>
          <div className="input_holder">
            <label className="input_label">نام کاربری</label>
            <input
              type="text"
              className="input_style"
              onChange={handleUserName}
              value={userName}
            ></input>
          </div>

          <div className="input_holder" onKeyPress={handleKeyPress}>
            <label className="input_label">رمز عبور</label>
            <input
              type="password"
              className="input_style"
              onChange={handlePassword}
              value={password}
            ></input>
          </div>
        </div>

        <button className="primary_btn" onClick={handleSubmit}>
          <ClipLoader
            size={12}
            css={override}
            color={"#ffffff"}
            loading={loading}
          />
          ورود
        </button>

        <div className="err_message_holder">
          <p className="err_txt">{errorText}</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
