import React, { useState, useEffect } from "react";
import "./SideBar.scss";
import { NavLink, Redirect } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";

export default function Sidebar() {
  const [exit, setExit] = useState(false);
  const [active, setIsActive] = useState(false);

  const handleExit = () => {
    axios
      .post(
        "https://server.risetech.ir/Admin/api/v1/profile/Logout",
        {},
        { headers: { token: cookie.load("token") } }
      )

      .then((res) => {
        cookie.remove("token");
        setExit(true);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  if (exit) {
    return <Redirect to="/" />;
  }

  return (
    <div className="side_holder">
      <div
        className={active ? "disable_body" : ""}
        onClick={() => setIsActive(!active)}
      ></div>
      <span
        className="icon-menu icon_menu"
        onClick={() => setIsActive(!active)}
      ></span>

      <aside className={active ? "panel_side show_nav" : "panel_side"}>
        <ul className="panel_menu">
          <NavLink
            to="/employee"
            className="menu_link"
            activeClassName="current_link"
          >
            <i className="icon-users" />
            کارمندان
          </NavLink>
          <NavLink
            to="/mounthlyreport"
            className="menu_link"
            activeClassName="current_link"
          >
            <i className="icon-file-text" />
            گزارش ماهانه{" "}
          </NavLink>
          <NavLink
            to="/project"
            className="menu_link"
            activeClassName="current_link"
          >
            <i className="icon-plus" />
            پروژه‌
          </NavLink>
          <a className="menu_link" onClick={handleExit}>
            <i className="icon-log-out" />
            خروج
          </a>
        </ul>
      </aside>
    </div>
  );
}
