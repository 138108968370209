
import Employee from "./Employee/Employee";
import Login from './login/Login'
import Report from './Report/Report'
import MounthlyReportList from './Report/MounthlyReportList'
import ProjectReport from './Report/ProjectReport'
import SubProjectReport from './Report/SubProjectReport'
import Project from "./Project/Project";
import SubProject from "./Project/SubProject";
import HourlyReport from "./Report/HourlyReport"

const routes = [
    
    {
        path : '/employee',
        component : Employee,
        exact : true,
    },   
    
    {
        path: "/",
        component: Login,
        exact : true,
    },
    {
        path: "/employee/report",
        component: Report,
        exact : true,
        strict : true
    },
    {
        path: "/employee/hourlyReport",
        component: HourlyReport,
        exact : true,
        strict : true
    },
    {
        path: "/mounthlyreport",
        component: MounthlyReportList,
        exact : true,
    },
    {
        path : '/project',
        component : Project,
        exact : true,
    },
    {
        path : '/project/subproject',
        component : SubProject,
        exact : true,
    },
    {
        path: "/project/projectreport",
        component: ProjectReport,
        exact : true,
    },
    {
        path: "/project/subprojectreport",
        component: SubProjectReport,
        exact : true,
    },
    
];


export default routes;