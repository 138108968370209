import React, { useState, useEffect } from "react";
import MainTemplate from "../MainTemplate";
import "./Employee.scss";
import axios from "axios";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const override = css`
  vertical-align: middle;
  margin-left: 6px;
`;

export const Employee = () => {
  const [status, setStatus] = useState(true);
  const [ActiveEmployee, setActiveEmployee] = useState([]);
  const [NoResults, setNoResults] = useState(false);
  const [page, setPage] = useState(1);
  const [moreDisable, setMoreDisable] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [AlertText, setAlertText] = useState("");
  const [submiting, setSubmiting] = useState(false);
  const [openConfirmDeactive, setOpenConfirmDeactive] = useState(false);
  const [RemoveID, setRemoveID] = useState("");
  const [RemoveEmployeeName, setRemoveEmployeeName] = useState("");
  const [removeLoading, setRemoveLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  function toPersianNum(str) {
    if (str == null) {
      return "";
    } else {
      str = str.toString();
      if (typeof str === "string") {
        for (var i = 0; i < 10; i++) {
          str = str.replaceAll(englishNumbers[i], persianNumbers[i]);
        }
      }
      return str;
    }
  }

  useEffect(() => {
    setActiveEmployee([]);

    axios
      .get(
        "https://server.risetech.ir/Admin/api/v1/Employee/List/per=10/page=0",
        { headers: { token: cookie.load("token") } }
      )
      .then((res) => {
        const Employee = res.data;

        if (Employee.length == 0) {
          setNoResults(true);
        } else {
          setActiveEmployee(res.data);

          if (Employee.length < 10) {
            setMoreDisable(true);
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [status]);

  const seeMore = () => {
    var page2 = page + 1;
    setPage(page2);

    setLoadMoreLoading(true);

    axios
      .get(
        "https://server.risetech.ir/Admin/api/v1/Employee/List/per=10/page=" +
          page +
          "",
        { headers: { token: cookie.load("token") } }
      )
      .then((res) => {
        setLoadMoreLoading(false);

        const Employee = res.data;

        if (Employee.length < 10) {
          setMoreDisable(true);
        }

        for (var i = 0; i < Employee.length; i++) {
          setActiveEmployee((ActiveEmployee) => [
            ...ActiveEmployee,
            Employee[i],
          ]);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoadMoreLoading(false);
      });
  };

  const RemoveEmployee = (event) => {
    setRemoveLoading(true);

    axios
      .post(
        "https://server.risetech.ir/Admin/api/v1/Employee/RemoveEmployee/" +
          RemoveID +
          "",
        {},
        { headers: { token: cookie.load("token") } }
      )
      .then((res) => {
        setStatus(!status);
        setOpenConfirmDeactive(false);
        setOpenMessage(true);
        setRemoveLoading(false);
        setAlertText(res.data.Message);
      })
      .catch((err) => {
        console.log(err.response);
        setOpenConfirmDeactive(false);
        setOpenMessage(true);
        setRemoveLoading(false);
        setAlertText(err.response.data.Message);
      });
  };

  const RestorEmployee = (id) => (event) => {
    axios
      .post(
        "https://server.risetech.ir//Admin/api/v1/Employee/RestorEmployee/" +
          id +
          "",
        {},
        { headers: { token: cookie.load("token") } }
      )
      .then((res) => {
        setStatus(!status);
        setOpenMessage(true);
        setAlertText(res.data.Message);
      })
      .catch((err) => {
        console.log(err.response);
        setOpenMessage(true);
        setAlertText(err.response.data.Message);
      });
  };

  const [FileVal, setFileVal] = useState("");
  const [FileType, setFileType] = useState("");

  const uploadImage = (event) => {
    const fileType = event.target.value.split(".").pop();
    setFileType(fileType);

    var files = event.target.files;

    var readerImage = new FileReader();
    readerImage.onload = function (e) {
      var ImageArrayBuffer = e.target.result;
      var base64String = btoa(ImageArrayBuffer);
      setFileVal(base64String);
    };
    readerImage.readAsBinaryString(files[0]);
  };

  const EmployeeID = (id) => (event) => {
    cookie.save("EmployeeID", id);
  };

  const [open, setOpen] = React.useState(false);
  const [Name, setName] = useState("");
  const [NameAlert, setNameAlert] = useState("");
  const [UserName, setUserName] = useState("");
  const [UserNameAlert, setUserNameAlert] = useState("");
  const [Password, setPassword] = useState("");
  const [PasswordAlert, setPasswordAlert] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenConfirmDeactive(false);
    setOpenMessage(false);
    setRemoveEmployeeName("");
    setRemoveID("");
  };
  const handleName = (event) => {
    var value = event.target.value;
    setName(value);
  };
  const handleUserName = (event) => {
    var value = event.target.value;
    setUserName(value);
  };
  const handlePassword = (event) => {
    var value = event.target.value;
    setPassword(value);
  };

  const insertEmployee = () => {
    setSubmiting(true);

    axios
      .post(
        "https://server.risetech.ir/Admin/api/v1/Employee/Insert",
        {
          Name: Name,
          UserName: UserName,
          Password: Password,
          Image: FileVal,
          Extension: FileType,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: cookie.load("token"),
          },
        }
      )
      .then((res) => {
        setOpen(false);
        setName("");
        setUserName("");
        setPassword("");
        setStatus(!status);
        setOpenMessage(true);
        setAlertText(res.data.Message);
        setSubmiting(false);
      })
      .catch((err) => {
        setOpenMessage(true);
        setAlertText(err.response.data.Message);
        setSubmiting(false);
      });
  };

  return (
    <MainTemplate>
      <div className="add_row">
        <button className="add_btn" onClick={handleClickOpen}>
          <span className="icon-plus"></span>
        </button>
      </div>

      <div className={NoResults ? "blankMessage" : "hidden"}>
        {"هیچ کارمندی وجود ندارد."}
      </div>

      <div
        className={NoResults ? "hidden" : "flex_space_between full_width"}
      ></div>

      <div className="flex_space_between">
        {ActiveEmployee.map((item, i) => (
          <div className="active_item_cell user_row">
            <p className="row_cell">{toPersianNum(i + 1)}</p>
            <p className="row_cell">
              <figure className="user_img">
                <img src={item.Image}></img>
              </figure>
            </p>
            <p className="row_cell">{item.Name}</p>
            <p className="row_cell">{item.Password}</p>
            <Link
              to="/employee/report"
              className="row_cell"
              onClick={EmployeeID(item.ID)}
            >
              مشاهده‌ی گزارش
            </Link>
            <Link
              to="/employee/hourlyReport"
              className="row_cell"
              style={{width:'30%',fontWeight:400, color:'#b5838d', cursor: 'pointer', fontSize:'0.8em'}}
              onClick={EmployeeID(item.ID)}
            >
              ورود و خروج
            </Link>
            <div className="row_cell">
              <span
                className="active_icon icon-check-square"
                onClick={() => (
                  setRemoveID(item.ID),
                  setRemoveEmployeeName(item.Name),
                  setOpenConfirmDeactive(true)
                )}
              ></span>
            </div>
          </div>
        ))}
      </div>

      {/* <div className="flex_space_between">    

            {DeActiveEmployee.map( (item,i) =>(
               <div className="deactive_item_cell user_row">
                    <p className="row_cell">{toPersianNum(i+1)}</p>
                    <p className="row_cell"><figure className="user_img"><img src={item.Image}></img></figure></p>
                    <p className="row_cell">{item.Name}</p>
                    <Link to="/employee/report" className="row_cell">مشاهده‌ی گزارش</Link>
                    <div className="row_cell">
                        <span className="active_icon icon-check-square" onClick={RestorEmployee(item.ID)} ></span>
                    </div>
                </div>
            ))}
        </div> */}

      <button
        className="default_btn see_more"
        onClick={seeMore}
        disabled={moreDisable}
      >
        <ClipLoader
          size={12}
          css={override}
          color={"#b5838d"}
          loading={loadMoreLoading}
        />
        مشاهده‌ی بیشتر
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"افزودن کارمند جدید"}
        </DialogTitle>
        <DialogContent>
          <div className="input_holder">
            <label className="input_label">نام و نام‌خانوادگی</label>
            <input
              type="text"
              className="input_style"
              value={Name}
              onChange={handleName}
            ></input>
          </div>
          <div className="input_holder">
            <label className="input_label">نام کاربری </label>
            <input
              type="text"
              className="input_style"
              value={UserName}
              onChange={handleUserName}
            ></input>
          </div>
          <div className="input_holder">
            <label className="input_label">رمز عبور </label>
            <input
              type="password"
              className="input_style"
              value={Password}
              onChange={handlePassword}
            ></input>
          </div>
          <div className="input_holder">
            <label className="input_label">عکس </label>
            <input
              type="file"
              className="input_style"
              onChange={uploadImage}
            ></input>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={insertEmployee} className="primary_btn dialog_btn">
            <ClipLoader
              size={12}
              css={override}
              color={"#ffffff"}
              loading={submiting}
            />
            ثبت
          </button>
          <button onClick={handleClose} className="default_btn dialog_btn">
            لفو
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openMessage}
        onClose={handleCloseMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>{AlertText}</DialogContent>
      </Dialog>

      <Dialog
        open={openConfirmDeactive}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          از حذف {RemoveEmployeeName} اطمینان دارید؟
        </DialogContent>
        <DialogActions>
          <button onClick={RemoveEmployee} className="primary_btn dialog_btn">
            <ClipLoader
              size={12}
              css={override}
              color={"#ffffff"}
              loading={removeLoading}
            />
            بله
          </button>
          <button onClick={handleClose} className="default_btn dialog_btn">
            خیر
          </button>
        </DialogActions>
      </Dialog>
    </MainTemplate>
  );
};
export default Employee;
