import React, { useState, useEffect } from "react";
import MainTemplate from "../MainTemplate";
import "./Report.scss";
import axios from "axios";
import cookie from "react-cookies";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { CSVLink, CSVDownload } from "react-csv";

const override = css`
  vertical-align: middle;
  margin-left: 6px;
`;

export const ProjectReport = () => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [ReportList, setReportList] = useState([]);
  const [NoResults, setNoResults] = useState(false);
  const [EmptyList, setEmptyList] = useState(true);
  const [TotalTime, setTotalTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [dateWarning, setDateWarning] = useState(false);

  const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  function toPersianNum(str) {
    if (str == null) {
      return "";
    } else {
      str = str.toString();
      if (typeof str === "string") {
        for (var i = 0; i < 10; i++) {
          str = str.replaceAll(englishNumbers[i], persianNumbers[i]);
        }
      }
      return str;
    }
  }

  const GetReport = () => {
    setTotalTime("");
    setEmptyList(true);
    setNoResults("");

    if (selectedDay == null) {
      setDateWarning(true);
    } else {
      setLoading(true);

      var Month = selectedDay.month;
      var Year = selectedDay.year;
      var Day = selectedDay.day;

      if (Day < 10) {
        Day = "0" + Day;
      } else {
        Day = Day;
      }

      if (Month < 10) {
        Month = "0" + Month;
      } else {
        Month = Month;
      }

      const Date = Year + "/" + Month + "/" + Day;

      axios
        .post(
          "https://server.risetech.ir/Admin/api/v1/Report/MounthlyProjectReportList",
          { Date: Date },
          { headers: { token: cookie.load("token") } }
        )
        .then((res) => {
          if (res.data.MounthlyReportList.length == 0) {
            setNoResults(true);
          } else {
            setReportList(res.data.MounthlyReportList);
            setEmptyList(false);
          }
          setTotalTime(res.data.TotalTime);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
          setLoading(false);
        });
    }
  };

  return (
    <MainTemplate>
      <div className="selectday_row">
        <DatePicker
          value={selectedDay}
          inputPlaceholder={"انتخاب ماه"}
          onChange={setSelectedDay}
          shouldHighlightWeekends
          inputClassName="input_style DatePicker"
          calendarClassName="responsive-calendar"
          locale="fa"
        />
        <button className="primary_btn" onClick={GetReport}>
          <ClipLoader
            size={12}
            css={override}
            color={"#ffffff"}
            loading={loading}
          />
          گزارش گیری
        </button>
      </div>

      <div className={NoResults ? "blankMessage" : "hidden"}>
        {"هیچ پروژه‌ای در این روز ثبت نشده است ."}
      </div>
      <div className="scroll_holder">
        <div
          className={EmptyList ? "hidden" : "report_list_holder project_report"}
        >
          <div className="header_list">
            <p className="row_cell"></p>
            <p className="row_cell">نام پروژه</p>
            <p className="row_cell">ساعت</p>
          </div>
          <div className="flex_space_between table">
            {ReportList.map((item, i) => (
              <div className="report_item">
                <p className="row_cell">{toPersianNum(i + 1)}</p>
                <p className="row_cell">{item.Name}</p>
                <p className="row_cell">{toPersianNum(item.Total)}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <p className="total">
        <span className="icon-clock"></span>مجموع ساعت کاری :{" "}
        <span className="time_style">{toPersianNum(TotalTime)}</span>
      </p>

      <div>
        <CSVLink
          data={ReportList}
          filename={"project.csv"}
          className="download_report_link default_btn"
        >
          <span className="icon-download"></span>
          دانلود گزارش
        </CSVLink>
      </div>
    </MainTemplate>
  );
};
export default ProjectReport;
