import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import routes from './component/routes';
import cookie from 'react-cookies';




export default function App() {

    const token = cookie.load('token');  

    if (token == undefined || token == 0 ) {
        return (
            <div>
                {routes.map((route) =>(
                    <Route {...route}></Route>
                ))}

                <Redirect to="/"/>
            </div>
        )
    } else {
        return(
            <div>                                
                {routes.map((route) =>(
                    <Route {...route}></Route>
                ))}
            </div>
        ) 
    }
}

