import React from 'react';
import Sidebar from './Sidebar/Sidebar';



function MainTemplate ({children}) {

    return(

        <div className="flex_space_between">
            <Sidebar /> 
            <div className="panel_content">
                {children}                               
            </div> 
        </div>   
    )
    
}


export default MainTemplate;