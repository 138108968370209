import React, { useState, useEffect } from "react";
import MainTemplate from "../MainTemplate";
import "./Report.scss";
import axios from "axios";
import cookie from "react-cookies";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { CSVLink, CSVDownload } from "react-csv";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import Employee from "../Employee/Employee";


const override = css`
  vertical-align: middle;
  margin-left: 6px;
`;

export const HourlyReport = () => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [ReportList, setReportList] = useState([]);
  const [NoResults, setNoResults] = useState(false);
  const [EmptyList, setEmptyList] = useState(true);
  const [TotalTime, setTotalTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [dateWarning, setDateWarning] = useState(false);
  const [LeaveCount, setLeaveCount] = useState("");
  const[InPersonCount, setInPersonCount] = useState("")
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [removeID, setRemoveID] = useState("");

  const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  function toPersianNum(str) {
    if (str == null) {
      return "";
    } else {
      str = str.toString();
      if (typeof str === "string") {
        for (var i = 0; i < 10; i++) {
          str = str.replaceAll(englishNumbers[i], persianNumbers[i]);
        }
      }
      return str;
    }
  }

  const GetReport = () => {
    setReportList([]);
    setTotalTime("");
    setEmptyList(true);
    setNoResults("");

    if (selectedDay == null) {
      setDateWarning(true);
    } else {
      setLoading(true);

      var Month = selectedDay.month;
      var Year = selectedDay.year;
      var Day = selectedDay.day;

      if (Day < 10) {
        Day = "0" + Day;
      } else {
        Day = Day;
      }

      if (Month < 10) {
        Month = "0" + Month;
      } else {
        Month = Month;
      }

      const Date = Year + "/" + Month + "/" + Day;

      axios
        .post(
          "https://server.risetech.ir/Admin/api/v1/Employee/EmployeePresenceReportList/" +
            cookie.load("EmployeeID") +
            "",
          { Date: Date },
          { headers: { token: cookie.load("token") } }
        )
        .then((res) => {
          console.log(res.data);

          if (res.data.ReportList.length == 0) {
            setNoResults(true);
          } else {
            setReportList(res.data.ReportList);
            setEmptyList(false);
          }
          setTotalTime(res.data.Total);
          setLeaveCount(res.data.LeaveCount);
          setInPersonCount(res.data.InPersonCount);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
          setLoading(false);
        });
    }
  };

  const closeDialog = () => {
    setOpenRemoveDialog(false);
  };

  const removeItem = () => {
    axios
      .post(
        "https://server.risetech.ir/Admin/api/v1/Report/RemoveReport/" +
          removeID +
          "",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            token: cookie.load("token"),
          },
        }
      )
      .then((res) => {
        setOpenRemoveDialog(false);
        GetReport();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <MainTemplate>
      <div className="selectday_row">
        <DatePicker
          value={selectedDay}
          inputPlaceholder={"انتخاب ماه"}
          onChange={setSelectedDay}
          shouldHighlightWeekends
          inputClassName="input_style DatePicker"
          calendarClassName="responsive-calendar"
          locale="fa"
        />
        <button className="primary_btn" onClick={GetReport}>
          <ClipLoader
            size={12}
            css={override}
            color={"#ffffff"}
            loading={loading}
          />
          گزارش گیری
        </button>
      </div>
      <div className="flex_space_between" style={{marginTop:50}}>
        <div className="active_item_cell user_row">
            <p className="row_cell">تاریخ</p>
            <p className="row_cell">ساعت ورود</p>
            <p className="row_cell">ساعت خروج</p>
        </div>
        <div className={NoResults ? "active_item_cell user_row" : "hidden"}>
            {"هیچ گزارشی برای این کارمند در این روز ثبت نشده است ."}
        </div>
        <div className={ReportList == [] ? "active_item_cell user_row" : "hidden"}>
            {"هیچ گزارشی برای این کارمند در این روز ثبت نشده است ."}
        </div>
        {ReportList.map((item, i) => (
          <div className="active_item_cell user_row">
            <p className="row_cell">{item.Date}</p>
            <p className="row_cell">{item.In}</p>
            <p className="row_cell">{item.Out}</p>
          </div>
        ))}
      </div>
      <CSVLink
        data={ReportList}
        filename={"report.csv"}
        className="download_report_link default_btn"
      >
        <span className="icon-download"></span>
        دانلود گزارش
      </CSVLink>

      <Dialog open={openRemoveDialog} onClose={closeDialog} maxWidth={"xl"}>
        <DialogContent className="conform_dialog_content">
          آیا مایل به حذف این گزارش هستید؟
        </DialogContent>
        <DialogActions className="dialog_action_row">
          <button onClick={removeItem} className="primary_btn">
            بله
          </button>
          <button onClick={closeDialog} className="default_btn">
            خیر
          </button>
        </DialogActions>
      </Dialog>
    </MainTemplate>
  );
};
export default HourlyReport;
