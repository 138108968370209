import React, { useState, useEffect } from "react";
import MainTemplate from "../MainTemplate";
import "./Project.scss";
import axios from "axios";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const override = css`
  vertical-align: middle;
  margin-left: 6px;
`;

export const SubProject = () => {
  const [status, setStatus] = useState(true);
  const [ActiveProject, setActiveProject] = useState([]);
  const [DeactiveProject, setDeactiveProject] = useState([]);
  const [NoResults, setNoResults] = useState(false);
  const [page, setPage] = useState(1);
  const [moreDisable, setMoreDisable] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [AlertText, setAlertText] = useState("");
  const [submiting, setSubmiting] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [projectID, setProjectID] = useState("");

  const [openConfirmDeactive, setOpenConfirmDeactive] = useState(false);
  const [SubProjectID, setSubProjectID] = useState("");
  const [SubProjectName, setSubProjectName] = useState("");
  const [SubProjectLoading, setSubProjectLoading] = useState(false);

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  function toPersianNum(str) {
    if (str == null) {
      return "";
    } else {
      str = str.toString();
      if (typeof str === "string") {
        for (var i = 0; i < 10; i++) {
          str = str.replaceAll(englishNumbers[i], persianNumbers[i]);
        }
      }
      return str;
    }
  }

  useEffect(() => {
    setActiveProject([]);
    setDeactiveProject([]);
    setNoResults(false);

    var projectID = getParameterByName("id");
    setProjectID(projectID);

    axios
      .get(
        "https://server.risetech.ir/Admin/api/v1/subProject/List/" +
          projectID +
          "/per=10/page=0",
        { headers: { token: cookie.load("token") } }
      )
      .then((res) => {
        const Project = res.data;

        if (Project.length == 0) {
          setNoResults(true);
        } else {
          for (var i = 0; i < Project.length; i++) {
            if (Project[i].Status == "True") {
              setActiveProject((ActiveProject) => [
                ...ActiveProject,
                Project[i],
              ]);
            }
            if (Project[i].Status == "False") {
              setDeactiveProject((DeactiveProject) => [
                ...DeactiveProject,
                Project[i],
              ]);
            }
          }

          if (Project.length < 10) {
            setMoreDisable(true);
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [status]);

  const seeMore = () => {
    var page2 = page + 1;
    setPage(page2);

    setLoadMoreLoading(true);

    axios
      .get(
        "https://server.risetech.ir/Admin/api/v1/subProject/List/per=10/page=" +
          page +
          "",
        { headers: { token: cookie.load("token") } }
      )
      .then((res) => {
        setLoadMoreLoading(false);

        const Project = res.data;

        if (Project.length < 10) {
          setMoreDisable(true);
        }

        for (var i = 0; i < Project.length; i++) {
          if (Project[i].Status == "True") {
            setActiveProject((ActiveProject) => [...ActiveProject, Project[i]]);
          }
          if (Project[i].Status == "False") {
            setDeactiveProject((DeactiveProject) => [
              ...DeactiveProject,
              Project[i],
            ]);
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoadMoreLoading(false);
      });
  };

  const RemoveProject = (event) => {
    axios
      .post(
        "https://server.risetech.ir/Admin/api/v1/subProject/Remove/" +
          SubProjectID +
          "",
        {},
        { headers: { token: cookie.load("token") } }
      )
      .then((res) => {
        setStatus(!status);
        setOpenConfirmDeactive(false);
        setOpenMessage(true);
        setSubProjectLoading(false);
        setAlertText(res.data.Message);
      })
      .catch((err) => {
        console.log(err.response);
        setOpenConfirmDeactive(false);
        setOpenMessage(true);
        setSubProjectLoading(false);
        setAlertText(err.response.data.Message);
      });
  };

  const RestorProject = (id) => {
    axios
      .post(
        "https://server.risetech.ir/Admin/api/v1/subProject/Restore/" + id + "",
        {},
        { headers: { token: cookie.load("token") } }
      )
      .then((res) => {
        setStatus(!status);
        setOpenMessage(true);
        setAlertText(res.data.Message);
      })
      .catch((err) => {
        console.log(err.response);
        setOpenMessage(true);
        setAlertText(err.response.data.Message);
      });
  };

  const [open, setOpen] = React.useState(false);
  const [Code, setCode] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenMessage(false);
    setOpenConfirmDeactive(false);
    setSubProjectName("");
    setSubProjectID("");
  };
  const handleCode = (event) => {
    var value = event.target.value;
    setCode(value);
  };

  const insertProject = () => {
    setSubmiting(true);

    axios
      .post(
        "https://server.risetech.ir/Admin/api/v1/subProject/Insert",
        {
          Code: Code,
          ProjectID: projectID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: cookie.load("token"),
          },
        }
      )
      .then((res) => {
        setOpen(false);
        setCode("");
        setStatus(!status);
        setOpenMessage(true);
        setAlertText(res.data.Message);
        setSubmiting(false);
      })
      .catch((err) => {
        setOpenMessage(true);
        setAlertText(err.response.data.Message);
        setSubmiting(false);
      });
  };

  return (
    <MainTemplate>
      <div className="add_row flex_space_between">
        <button className="add_btn" onClick={handleClickOpen}>
          <span className="icon-plus"></span>
        </button>
        <Link to="/project/subprojectreport" className="link_btn">
          گزارش ماهانه کد پروژه <span className="icon-chevron-left"></span>
        </Link>
      </div>

      <div className={NoResults ? "blankMessage" : "hidden"}>
        {"هیچ پروژه‌ای وجود ندارد."}
      </div>

      <div
        className={NoResults ? "hidden" : "flex_space_between full_width"}
      ></div>

      <div className="flex_space_between">
        {ActiveProject.map((item, i) => (
          <div className="active_item_cell subproject_row">
            <p className="row_cell">{toPersianNum(i + 1)}</p>
            <p className="row_cell">کد پروژه : {toPersianNum(item.Code)}</p>
            <div className="row_cell">
              <span
                className="active_icon icon-check-square"
                onClick={() => (
                  setSubProjectID(item.ID),
                  setSubProjectName(item.Code),
                  setOpenConfirmDeactive(true)
                )}
              ></span>
            </div>
          </div>
        ))}
        {DeactiveProject.map((item, i) => (
          <div className="deactive_item_cell subproject_row">
            <p className="row_cell">{toPersianNum(i + 1)}</p>
            <p className="row_cell">کد پروژه : {toPersianNum(item.Code)}</p>
            <div className="row_cell">
              <span
                className="active_icon icon-check-square"
                onClick={() => RestorProject(item.ID)}
              ></span>
            </div>
          </div>
        ))}
      </div>

      <button
        className="default_btn see_more"
        onClick={seeMore}
        disabled={moreDisable}
      >
        <ClipLoader
          size={12}
          css={override}
          color={"#b5838d"}
          loading={loadMoreLoading}
        />
        مشاهده‌ی بیشتر
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"افزودن کد پروژه جدید"}
        </DialogTitle>
        <DialogContent>
          <div className="input_holder">
            <label className="input_label">کد پروژه‌</label>
            <input
              type="text"
              className="input_style"
              value={Code}
              onChange={handleCode}
            ></input>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={insertProject} className="primary_btn dialog_btn">
            <ClipLoader
              size={12}
              css={override}
              color={"#ffffff"}
              loading={submiting}
            />
            ثبت
          </button>
          <button onClick={handleClose} className="default_btn dialog_btn">
            لفو
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openMessage}
        onClose={handleCloseMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>{AlertText}</DialogContent>
      </Dialog>

      <Dialog
        open={openConfirmDeactive}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          از غیرفعال کردن کد {SubProjectName} اطمینان دارید؟
        </DialogContent>
        <DialogActions>
          <button onClick={RemoveProject} className="primary_btn dialog_btn">
            <ClipLoader
              size={12}
              css={override}
              color={"#ffffff"}
              loading={SubProjectLoading}
            />
            بله
          </button>
          <button onClick={handleClose} className="default_btn dialog_btn">
            خیر
          </button>
        </DialogActions>
      </Dialog>
    </MainTemplate>
  );
};
export default SubProject;
